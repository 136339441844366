import React from 'react'
import { graphql } from 'gatsby'
import SEO from '~/components/00-global/util/seo'
import Product from '~/components/03-product/product'
import { useIntl } from 'gatsby-plugin-intl'

const ProductPage = ({ data }) => {
  const { locale } = useIntl()

  const product = data.product.nodes.filter(
    product => product.locale === locale
  )[0]
  const products = data.products.nodes.filter(
    product => product.locale === locale
  )

  return (
    <>
      <SEO
        title={product.title}
        description={product.description}
        pathname={`/produkt/${product.handle}`}
        image={product.images[0].localFile.publicURL}
      />
      <Product product={product} products={products} />
    </>
  )
}

export const { product } = graphql`
  query($handle: String!) {
    product: allShopifyProduct(filter: { handle: { eq: $handle } }) {
      nodes {
        id
        locale
        title
        handle
        productType
        description
        descriptionHtml
        shopifyId
        options {
          id
          name
          values
        }
        variants {
          availableForSale
          id
          title
          price {
            amount
          }
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          largeImage: image {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images: images {
          id
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        largeImages: images {
          id
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    products: allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
      nodes {
        id
        locale
        title
        productType
        tags
        handle
        createdAt
        images {
          id
          originalSrc
          localFile {
            childImageSharp {
              fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        variants {
          availableForSale
          id
          title
          price {
            amount
          }
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`

export default ProductPage

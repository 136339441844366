import React, { useState, useEffect, useContext, useMemo } from 'react'
import Img from 'gatsby-image'
import isEqual from 'lodash/isEqual'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Thumbs, A11y } from 'swiper'
import { motion } from 'framer-motion'
import { GlassMagnifier } from 'react-image-magnifiers'

// Components
import Button from '~/components/00-global/Button/Button'
import StoreContext from '~/context/StoreContext'
import getPrice from '~/components/00-global/util/price'
import GetWindowDimensions from '~/components/00-global/util/getWindowDimensions'
import { Load, Scroll } from '~/components/00-global/Animation/Animation'

// Styles
import styleVariables from '~/layouts/variables.scss'
import styles from './product.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import 'swiper/swiper.scss'
import 'swiper/components/thumbs/thumbs.scss'
import 'swiper/components/navigation/navigation.scss'
import './swiper.scss'

// Icons
import NextIcon from '~/images/icon-next.svg'
import PrevIcon from '~/images/icon-prev.svg'
import IconShield from '~/images/icon-shield.svg'
import IconPayPal from '~/images/icon-pay-pal.svg'
import IconAmazonPay from '~/images/icon-amazon-pay.svg'

SwiperCore.use([Navigation, Thumbs, A11y])

const ScrollBox = ({ name, children }) => (
  <div className={styles.scrollBoxContainer}>
    <h2 className={styles.name}>{name}</h2>
    <div className={styles.row}>
      <div className={styles.overlayLeft} />
      <div className={styles.overlayRight} />
      <div className={styles.scrollContainer}>
        <div className={styles.scrollChild}>{children}</div>
      </div>
    </div>
  </div>
)

const Product = ({ product }) => {
  const intl = useIntl()

  const {
    addVariantToCart,
    store: { adding }
  } = useContext(StoreContext)

  // ---- SLIDER ----

  const xs =
    typeof document !== `undefined` ? styleVariables.xs.replace(/\D+/, '') : ''
  const s =
    typeof document !== `undefined` ? styleVariables.s.replace(/\D+/, '') : ''
  const sm =
    typeof document !== `undefined` ? styleVariables.sm.replace(/\D+/, '') : ''
  const md =
    typeof document !== `undefined` ? styleVariables.md.replace(/\D+/, '') : ''
  const lg =
    typeof document !== `undefined` ? styleVariables.lg.replace(/\D+/, '') : ''
  const xl =
    typeof document !== `undefined` ? styleVariables.xl.replace(/\D+/, '') : ''
  const xxl =
    typeof document !== `undefined` ? styleVariables.xxl.replace(/\D+/, '') : ''

  const variantImageIds = useMemo(
    () => product.variants.flatMap(variant => variant.image.id),
    [product.variants]
  )

  const filteredImages = useMemo(
    () =>
      /* If there is only one image add the image to the filtered images to avoid build error and 
      don't add it on variant change down below to avoid duplicate image shown
    */
      product.images.length === 1
        ? product.images
        : product.images.filter(image => {
            const id = image.id.substring(4)
            return !variantImageIds.includes(id)
          }),
    [product.images, variantImageIds]
  )

  const images = filteredImages.map(image => ({
    image: image,
    largeImage: product.largeImages.find(
      largeImage => largeImage.id === image.id
    )
  }))

  const [swiperItems, setSwiperItems] = useState(images)
  const [activeIndex, setActiveIndex] = useState(0)
  const [mainSwiper, setMainSwiper] = useState()
  const [thumbsSwiper, setThumbsSwiper] = useState()
  const [thumbsSwiperStart, setThumbsSwiperStart] = useState(true)
  const [thumbsSwiperEnd, setThumbsSwiperEnd] = useState(false)

  const slideToVariant = index => {
    mainSwiper?.slideTo(index)
    thumbsSwiper?.slideTo(index)
  }

  const { windowWidth } = GetWindowDimensions()

  const nextButton = React.createRef()
  const prevButton = React.createRef()

  const keyDown = event => {
    // Arrow right key
    if (event.keyCode === 39) {
      nextButton.current.focus()
      mainSwiper?.slideNext()
      thumbsSwiper?.slideNext()
    }

    // Arrow left key
    if (event.keyCode === 37) {
      prevButton.current.focus()
      mainSwiper?.slidePrev()
      thumbsSwiper?.slidePrev()
    }
  }

  // Check for pressed keys
  useEffect(() => {
    document.addEventListener('keydown', keyDown, false)
    return () => {
      document.removeEventListener('keydown', keyDown, false)
    }
  })

  // ---- CART ----

  const handleAddToCart = () => {
    const removeLanguageId = id => id.substring(4)
    addVariantToCart(removeLanguageId(selectedVariant.shopifyId), 1)
  }

  // ---- PRODUCTS ----
  const [selectedOptions, setSelectedOptions] = useState(
    product.variants[0].selectedOptions.reduce(
      (array, option) => [...array, { name: option.name, value: option.value }],
      []
    )
  )
  const [selectedVariant, setSelectedVariant] = useState()

  // Set variant to selectedVariant on variant change
  useEffect(() => {
    const index = product.variants.findIndex(variant =>
      isEqual(variant.selectedOptions, selectedOptions)
    )

    if (index === -1) {
      setSelectedVariant()
      setSwiperItems(images)
    } else {
      slideToVariant(0)
      setSelectedVariant(product.variants[index])

      // Show variant image only if there is more than 1 image on the product to avoid duplication of image
      product.images.length > 1 &&
        setSwiperItems([
          {
            image: product.variants[index].image,
            largeImage: product.variants[index].largeImage
          },
          ...images
        ])
    }
  }, [selectedOptions])

  const variantExists = (optionIndex, value) => {
    // TODO: create this function dynamically
    switch (optionIndex) {
      case 0:
        return product.variants.find(
          variant => variant.selectedOptions[optionIndex].value === value
        )
      case 1:
        return product.variants.find(
          variant =>
            variant.selectedOptions[optionIndex - 1].value ===
              selectedOptions[optionIndex - 1].value &&
            variant.selectedOptions[optionIndex].value === value
        )

      case 2:
        return product.variants.find(
          variant =>
            variant.selectedOptions[optionIndex - 2].value ===
              selectedOptions[optionIndex - 2].value &&
            variant.selectedOptions[optionIndex - 1].value ===
              selectedOptions[optionIndex - 1].value &&
            variant.selectedOptions[optionIndex].value === value
        )

      case 3:
        return product.variants.find(
          variant =>
            variant.selectedOptions[optionIndex - 3].value ===
              selectedOptions[optionIndex - 3].value &&
            variant.selectedOptions[optionIndex - 2].value ===
              selectedOptions[optionIndex - 2].value &&
            variant.selectedOptions[optionIndex - 1].value ===
              selectedOptions[optionIndex - 1].value &&
            variant.selectedOptions[optionIndex].value === value
        )
      default:
        return false
    }
  }

  const getVariantIndex = (optionIndex, value) => {
    // TODO: create this function dynamically
    switch (optionIndex) {
      case 0:
        return product.variants.findIndex(
          variant => variant.selectedOptions[optionIndex].value === value
        )
      case 1:
        return product.variants.findIndex(
          variant =>
            variant.selectedOptions[optionIndex - 1].value ===
              selectedOptions[optionIndex - 1].value &&
            variant.selectedOptions[optionIndex].value === value
        )

      case 2:
        return product.variants.findIndex(
          variant =>
            variant.selectedOptions[optionIndex - 2].value ===
              selectedOptions[optionIndex - 2].value &&
            variant.selectedOptions[optionIndex - 1].value ===
              selectedOptions[optionIndex - 1].value &&
            variant.selectedOptions[optionIndex].value === value
        )

      case 3:
        return product.variants.findIndex(
          variant =>
            variant.selectedOptions[optionIndex - 3].value ===
              selectedOptions[optionIndex - 3].value &&
            variant.selectedOptions[optionIndex - 2].value ===
              selectedOptions[optionIndex - 2].value &&
            variant.selectedOptions[optionIndex - 1].value ===
              selectedOptions[optionIndex - 1].value &&
            variant.selectedOptions[optionIndex].value === value
        )
      default:
        return false
    }
  }

  const handleOptionChange = (optionIndex, value) => {
    let newSelectedOptions = selectedOptions
    newSelectedOptions[optionIndex].value = value

    setSelectedOptions([...newSelectedOptions])
  }

  const getProduct = () => {
    return (
      <>
        {product.options[0].values[0] !== 'Default Title' &&
          product.options[0].values[0] !== 'Default title' &&
          product.options.map((option, optionIndex) => (
            <ScrollBox name={option.name} key={option.name}>
              {option.values.map(value => {
                if (optionIndex === 0) {
                  return (
                    <Col
                      className={styles.col}
                      xs='auto'
                      key={`${option.name}-${value}`}
                    >
                      <button
                        className={
                          selectedOptions[optionIndex].value === value
                            ? `${styles.variant} ${styles.selected}`
                            : styles.variant
                        }
                        onClick={() => handleOptionChange(optionIndex, value)}
                      >
                        <Img
                          className={styles.image}
                          fluid={
                            product.variants[
                              getVariantIndex(optionIndex, value)
                            ].image.localFile.childImageSharp.fluid
                          }
                        />
                        <p className={styles.title}>{value}</p>
                      </button>
                    </Col>
                  )
                } else if (variantExists(optionIndex, value)) {
                  return (
                    <Col
                      className={styles.col}
                      xs='auto'
                      key={`${option.name}-${value}`}
                    >
                      <button
                        className={
                          selectedOptions[optionIndex].value === value
                            ? `${styles.variant} ${styles.selected}`
                            : styles.variant
                        }
                        onClick={() => handleOptionChange(optionIndex, value)}
                      >
                        <Img
                          className={styles.image}
                          fluid={
                            product.variants[
                              getVariantIndex(optionIndex, value)
                            ]?.image.localFile.childImageSharp.fluid
                          }
                        />

                        <p className={styles.title}>{value}</p>
                      </button>
                    </Col>
                  )
                } else {
                  return null
                }
              })}
            </ScrollBox>
          ))}
      </>
    )
  }

  return (
    <Load>
      <section className={styles.product}>
        <Container>
          <Row className={styles.row1}>
            <Col xs='12' md='5' className={styles.images}>
              {windowWidth < md && <h1>{product.title}</h1>}
              <div className={styles.sliderShapeContainer}>
                <div className={styles.shapeContainer} />
                <div className={styles.sliderContainer}>
                  <div className={styles.activeImageSliderContainer}>
                    <div className={styles.activeImageSlider}>
                      <Swiper
                        onActiveIndexChange={e => setActiveIndex(e.activeIndex)}
                        onSwiper={setMainSwiper}
                        thumbs={{ swiper: thumbsSwiper }}
                        autoHeight
                        navigation={{
                          nextEl: '#swiper-button-next',
                          prevEl: '#swiper-button-prev'
                        }}
                      >
                        {swiperItems.map((image, index) => (
                          <SwiperSlide key={'slide-' + index}>
                            <Img
                              key={index}
                              className={styles.image}
                              fluid={
                                image.image.localFile.childImageSharp.fluid
                              }
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <GlassMagnifier
                        className={styles.glassMagnifier}
                        magnifierSize='70%'
                        magnifierBorderColor='rgba(255, 255, 255, 0.8)'
                        largeImageSrc={
                          swiperItems[activeIndex].largeImage.localFile
                            .childImageSharp.fluid.src
                        }
                        imageSrc={
                          swiperItems[activeIndex].image.localFile
                            .childImageSharp.fluid.src
                        }
                      />
                    </div>
                    <button
                      ref={prevButton}
                      id='swiper-button-prev'
                      className='swiper-button prev'
                      aria-label={intl.formatMessage({
                        id: 'product.prevImage'
                      })}
                    >
                      <img src={NextIcon} alt='' />
                    </button>
                    <button
                      ref={nextButton}
                      id='swiper-button-next'
                      className='swiper-button next'
                      aria-label={intl.formatMessage({
                        id: 'product.nextImage'
                      })}
                    >
                      <img src={PrevIcon} alt='' />
                    </button>
                  </div>
                  <div className={styles.thumbnailsSlider} id='thumbnails'>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      onFromEdge={e => {
                        // When image count is one more than what fits in one slide view group, onFromEdge is called simultaneously with onReachEnd and onReachBeginning resulting in overwriting them. That is why we add isEnd and isBeginning check for that case.
                        if (e.isEnd) {
                          setThumbsSwiperStart(false)
                        } else if (e.isBeginning) {
                          setThumbsSwiperEnd(false)
                        } else {
                          setThumbsSwiperStart(false)
                          setThumbsSwiperEnd(false)
                        }
                      }}
                      onReachEnd={() => {
                        setThumbsSwiperEnd(true)
                      }}
                      onReachBeginning={() => {
                        setThumbsSwiperStart(true)
                      }}
                      watchSlidesVisibility
                      watchSlidesProgress
                      breakpoints={{
                        [xs]: { slidesPerView: 4 },
                        [s]: { slidesPerView: 4 },
                        [sm]: { slidesPerView: 6 },
                        [md]: { slidesPerView: 3 },
                        [lg]: { slidesPerView: 4 },
                        [xl]: { slidesPerView: 5 },
                        [xxl]: { slidesPerView: 7 }
                      }}
                      spaceBetween={10}
                    >
                      {swiperItems.map((image, index) => (
                        <SwiperSlide key={'thumb-' + index}>
                          <button tabIndex={-1}>
                            <div className='overlay' />
                            <Img
                              key={index}
                              className={styles.image}
                              fluid={
                                image.image.localFile.childImageSharp.fluid
                              }
                            />
                          </button>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <motion.div
                      animate={{ opacity: thumbsSwiperStart ? 0 : 1 }}
                      className={styles.overlayLeft}
                    />
                    <motion.div
                      animate={{ opacity: thumbsSwiperEnd ? 0 : 1 }}
                      className={styles.overlayRight}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col xs='12' md='7' className={styles.options}>
              {windowWidth >= md && <h1>{product.title}</h1>}
              {getProduct()}
              <div className={styles.optionsInfo}>
                <p className={styles.delivery}>
                  <FormattedMessage id='product.delivery' />
                </p>
                <p className={styles.total}>
                  {selectedVariant
                    ? getPrice(selectedVariant.price.amount)
                    : 'Variante auswählen'}
                </p>
                <p className={styles.vat}>
                  <FormattedMessage id='product.priceInfo' />
                </p>
                {!selectedVariant?.availableForSale && (
                  <p className={styles.available}>
                    <FormattedMessage id='product.productNotAvailable' />
                  </p>
                )}
              </div>
              <Scroll>
                <Button
                  onClick={() => {
                    handleAddToCart()
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                  disabled={!selectedVariant?.availableForSale || adding}
                  fullwidth
                >
                  <FormattedMessage id='product.addToCart' />
                </Button>
              </Scroll>
            </Col>
          </Row>
          <hr className={styles.separator} />
          <Row className={styles.row2}>
            <Col className={styles.description} xs='12' lg='7' xl='8'>
              <h2>
                <FormattedMessage id='product.titleDescription' />
              </h2>
              <div
                className={styles.descriptionText}
                dangerouslySetInnerHTML={{
                  __html: product.descriptionHtml
                }}
              />
            </Col>

            <Col className={styles.paySecure} xs='12' lg='5' xl='4'>
              <hr className={styles.separator} />

              <h2 className={styles.paySecureTitle}>
                <FormattedMessage id='product.titleShopSecure' />
              </h2>
              <div className={styles.paymentMethodsContainer}>
                <img className={styles.shield} src={IconShield} alt='' />
                <Row className={styles.row}>
                  <Col className={styles.col} xs='4'>
                    <div className={styles.paymentMethodContainer}>
                      <img
                        src={IconPayPal}
                        alt='PayPal'
                        width={'100%'}
                        height={'100%'}
                      />
                    </div>
                  </Col>
                  <Col className={styles.col} xs='4'>
                    <div className={styles.paymentMethodContainer}>
                      <img
                        src={IconAmazonPay}
                        alt='Amazon Pay'
                        width={'100%'}
                        height={'100%'}
                      />
                    </div>
                  </Col>
                  <Col className={styles.col} xs='4'>
                    <div className={styles.paymentMethodContainer}>
                      <div className={styles.customWord}>
                        <FormattedMessage id='product.prepayment' />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Load>
  )
}

export default Product
